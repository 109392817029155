
  export default {
    name: `NavigationButtons`,
    props: {
      buttonClear: {
        type: Boolean,
        default: true
      },
      buttonBack: {
        type: Boolean,
        default: false
      },
      buttonNext: {
        type: Boolean,
        default: true
      },
      buttonClearDisabled: {
        type: Boolean,
        default: false
      },
      buttonBackDisabled: {
        type: Boolean,
        default: false
      },
      buttonNextDisabled: {
        type: Boolean,
        default: false
      },
      buttonClearLoading: {
        type: Boolean,
        default: false
      },
      buttonBackLoading: {
        type: Boolean,
        default: false
      },
      buttonNextLoading: {
        type: Boolean,
        default: false
      },
      textClear: {
        type: String,
        default: undefined
      },
      textBack: {
        type: String,
        default: undefined
      },
      textNext: {
        type: String,
        default: undefined
      }
    }
  }
